/* global fbq */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof fbq === 'function') {
            fbq('track', 'PageView'); // Track every page view
        }
    }, [location]);

    return null;
};

export default TrackPageView;
